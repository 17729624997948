
export default {
    name: 'Country',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            skeleton: true,
            country: {},
            search: {
                val: ''
            },
            area: {
                origin: [],
                list: [],
                bar: [],
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.getCountryAjax()
    },
    methods: {
        // 获取国家列表
        getCountryAjax() { // 获取国家列表
            this.$api.common.getCountryList().then(response => {
                const res = response.result || [];
                this.country = res?.find((item)=>{
                    return this.country?.countryCode == item?.countryCode
                })
                this.area.origin = res
                this.setData(res)
                this.skeleton = false
            })
        },
        // 数据处理
        setData(res) {
            let areaObj = {}
            this.area.list = []
            this.area.bar = []
            res.forEach(p => {
                let oneStr = '', reg = /^\d{5}$|^\d{5}(-\d{4})$/;
                oneStr = p.englishName.slice(0, 1)
                if(!areaObj[oneStr]) {
                    areaObj[oneStr] = {}
                    areaObj[oneStr].list = []
                    this.area.bar.push({
                        key: oneStr,
                        className: `country-title-${oneStr}`,
                    })
                }
                areaObj[oneStr].list.push(p)
            })
            Object.keys(areaObj).forEach((key, val) => {
                this.area.list.push({
                    title: key,
                    className: `country-title-${key}`,
                    list: areaObj[key].list
                })
            })
        },
        // bar
        handleBar(obj) {
            this.$VueScrollTo.scrollTo(`.${obj.className}`, '', {
                offset: -118
            })
        },
        // 国家选择
        handleCountry(obj) {
            const origin = location.origin;
            const mRealmName = obj.mRealmName;
            const hasLocalHost = origin.includes('.10.10');
            // if(mRealmName == origin || hasLocalHost) {
                // 同域名
                // this.$storage.set('country', obj, true)
                this.$storage.set('countryStorage', obj, true)
                this.$storage.set('editAddress', obj, true)
                // this.$storage.set('currency', {
                //     code: obj.currencyCode,
                //     symbol: obj.currencySymbol
                // }, true)
                this.$storage.remove('order/placeAddress')
                this.$router.back()
            // } else {
            //     // 非同域名
            //     const route = this.$route;
            //     const deviceId = this.$storage.get('deviceId');
            //     const token = this.$storage.get('token');
            //     const userId = this.$storage.get('userId');
            //     // link参数
            //     let query = route.query;
            //     deviceId && (query.deviceId = deviceId)
            //     token && (query.token = token)
            //     userId && (query.userId = userId)
            //     delete query.country_code
            //     mRealmName.includes('www.yfn.com') && (query.country_code = obj.countryCode)
            //     // link拼接
            //     let queryArr = [];
            //     Object.keys(query).forEach(key => {
            //         queryArr.push(`${key}=${query[key]}`)
            //     })
            //     const fromPage = sessionStorage.getItem('history') || '';
            //     const queryAttr = queryArr.join('&');
            //     const symbol = queryAttr ? '?' : '';
            //     const hasJumpHome = fromPage.includes('Setting');
            //     const href = hasJumpHome ? `${mRealmName}${symbol}${queryAttr}` : `${mRealmName}${route.path}${symbol}${queryAttr}`;
            //     window.location.href = href
            // }
        },
        // 联想搜索
        inputSearch(val) {
            let legend = this.area.origin.filter(item => {
                let bool = false;
                bool = item.englishName.toLocaleLowerCase().includes(val.toLocaleLowerCase())
                return bool
            });
            this.setData(legend)
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Country/Region'),
                keywords: this.$translate('Country/Region'),
                description: this.$translate('Country/Region'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.country = this.$storage.get('country') || {}

            this.query = {
                ...this.$route.query,
            }
            if(this.query?.key=='editAddress'){
                // let storage  = this.$storage.getAll();
                // this.form = storage[this.query.key]
                this.query?.countryCode && (this.country.countryCode = this.query?.countryCode)
                this.query?.englishName && (this.country.englishName = this.query?.englishName)
            }
        }
    },
}
