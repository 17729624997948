
import GoogleMap from '/utils/common/google-map.js'
const googleMap = new GoogleMap()

export default {
    name: 'AddressLine',
    props: {
        check: Object,
        rules: Object,
        query: Object,
        form: Object,
        country: Object
    },
    data() {
        return {
            clear: '',
            // country: {},
            list: [],
            codeList: [],
            googleList: [],
            target: {},
            hasPopoverStatus: false,
            regionCompo: {           // 地区选择组件配置项
                type: 1,             // 1 州/省 2 市/区
                state: '',
                city: ''
            },
            popupCompo: {            // 弹窗组件配置项
                visible: false
            }
        }
    },
    computed: {},
    watch: {
        rules: {
            handler(n ,o) {
                this.initialSet()
            },
            deep: true
        },
        'check.hasCity': {
            handler(n ,o) {
                this.setCityStatus()
            }
        }
    },
    created() {},
    mounted() {},
    methods: {
        countryRe(name){
            return this.$fnApi.mixData.addressSuffix(name,this, {
                countryCode: this.country.countryCode
            })
        },
        // 表单提交
        submit() {
            this.check.hasAddress = true
        },
        failed() {
            this.check.hasAddress = false
        },
        // 设置
        handleSet(obj) {
            this.setRegion(obj)
        },
        handleSetFull(obj) {
            if(obj.icon && obj.hasFullHot) {
                this.setRegion(obj)
            }
        },
        inputBlur(e,obj){
            if(['postCode', 'addressLine1'].includes(obj.list?.key)){
                obj.list.hasPopoverStatus = false
            }
        },
        // 输入框
        inputAddress(e, obj) { // 输入
            this.setSpm(obj)
            this.check.hasChange = true
            if(obj.list?.key == 'postCode') {
                this.inputCodeAddress(e, obj)
                this.$forceUpdate()
            }
            if(obj.list?.key == 'addressLine1') {
                this.inputStateAddress(e, obj)
            }
            if(obj.list?.key=='vatNumber'){
                this.inputCPFcode(e, obj)
            }
        },
        inputCPFcode(e, obj){
            let value = e.replace(/\D/g, '');

            let formattedValue = value
                .replace(/(\d{3})(\d)/, '$1.$2') // 第1个点
                .replace(/(\d{3})(\d)/, '$1.$2') // 第2个点
                .replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // 第1个连字符
            // if(this.form[obj.list.key].length<formattedValue.length){
            formattedValue.length == 3 || formattedValue.length == 7
                ? (formattedValue = formattedValue + '.')
                : formattedValue.length == 11
                ? (formattedValue = formattedValue + '-')
                : '';
            // }
            this.form[obj.list.key] = formattedValue
        },
        inputCodeAddress(e, obj) {
            // code
            this.target = obj
            this.$fnApi.debounce(() => {
                e.length > 2 && this.getCodeAreaAjax()
            }, 300)
        },
        inputStateAddress(e, obj) {
            // addressLine1
            this.target = obj
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                if(e) {
                    this.getGooglePlaces(e).then(res => {
                        this.googleList = res
                        this.hasPopoverStatus = !!res.length
                        this.setPopoverStatus()
                    })
                } else {
                    this.hasPopoverStatus = false
                }
            }, 300)
        },
        clearAddress(e, obj) { // 清除
            if(!['postCode'].includes(obj.list?.key)) {
                return
            }
            this.target = obj
            this.hasPopoverStatus = false
            this.setPopoverStatus()
        },
        // 谷歌地址搜索联想
        getGooglePlaces(v) {
            const language = this.$storage.get('language')
            return new Promise((resolve) => {
                const options = {
                    input: v,
                    country: this.country.countryCode,
                    language: language.langCode
                };
                googleMap.autocomplete(options, res => {
                    let r = res.list || [];
                    let rList = [];
                    r.forEach(item => {
                        let obj = {
                            street: '', // 街道地址
                            route: '', // 路线地址
                            city: '', // 城市
                            state: '', // 洲/省
                            post_code: '' // 邮编
                        };
                        let types = [];
                        item.address_components.forEach(d => {
                            types = [...types, ...d.types]
                        })
                        let stateKey = 'administrative_area_level_1';
                        let cityKey = 'locality';
                        if(!types.includes(stateKey)) {
                            stateKey = 'administrative_area_level_2'
                        }
                        if(!types.includes(cityKey)) {
                            cityKey = 'postal_town'
                        }
                        item.address_components.forEach(d => {
                            d.types.includes('street_number') && (obj.street = d.long_name)
                            d.types.includes('route') && (obj.route = d.long_name)
                            d.types.includes(cityKey) && (obj.city = d.long_name)
                            d.types.includes(stateKey) && (obj.state = d.long_name)
                            d.types.includes('postal_code') && (obj.post_code = d.long_name)
                        })
                        if(obj.street || obj.route) {
                            rList.push(obj)
                        }
                    })
                    resolve(rList)
                })
            })
        },
        // 获取code地区
        getCodeAreaAjax() {
            this.$api.address.getPostCodeAddress({
                countryCode: this.country.countryCode,
                postcode: this.form.postCode
            }).then(response => {
                const res = response.result || [];
                this.codeList = res
                this.hasPopoverStatus = !!this.codeList.length
                this.setPopoverStatus()
            })
        },
        setPopoverStatus() {
            try {
               this.list.some((item, i) => {
                   item.list.some((list => {
                       if(list?.key == this.target?.list?.key) {
                           list.hasPopoverStatus = this.hasPopoverStatus
                           return true
                       }
                   }))
               }) 
            } catch (error) {
                console.log(error)
            }
        },
        handlePostArea(obj) { // 设置地区
            this.check.hasChange = true
            this.$set(this.form, 'state', obj.stateOrProvince)
            this.$set(this.form, 'city', obj.city)
            if(obj.postcode){
                this.form.postCode = obj.postcode || ''
            }
            if(obj.street) {
                this.form.addressLine1 = obj.street
            }
            this.hasPopoverStatus = false
            this.setPopoverStatus()
        },
        // 设置城市选择/填写状态
        setCityStatus() {
            let r = [], index = 0;
            this.list.some((item, i) => {
                r = item.list.filter(f => {
                    return f?.key == 'city'
                })
                if(r.length) {
                    index = i
                    return true
                }
            })
            if(r.length) {
                r[0].readonly = this.check.hasCity
                r[0].icon = this.check.hasCity
                this.$set(this.list, index, {...this.list[index], list: r})
            }
        },
        // 地区选择
        setRegion(obj) {
            this.check.hasChange = true
            if(['state', 'city'].includes(obj?.key)) {
                this.popupCompo.visible = true
                this.regionCompo.type = ['state', 'city'].indexOf(obj?.key) + 1
                this.regionCompo.state = this.form.state
                this.regionCompo.city = this.form.city
                const ref = this.$refs.YfnRegion;
                ref && (ref.tab.id = this.regionCompo.type)
            } else {
                this.$emit('emit-handle-set', obj)
            }
        },
        emitChangeRegion(obj) { // 地区选择 change
            this.form.state = obj.form.state
            // this.form.city = obj.form.city
            // 设置城市状态
            if(obj.area.hasAllSelect) {
                this.popupCompo.visible = false
                // this.check.hasCity = obj.area.hasCity
                // this.setCityStatus()
            }
        },
        emitInputRegion(obj) {
            this.setSpm({
                d: 20,
                pageId: obj.val
            })
        },
        // 设置城市选择/填写状态
        setCityStatus() {
            let r = [], index = 0;
            this.list.some((item, i) => {
                r = item.list.filter(f => {
                    return f?.key == 'city'
                })
                if(r.length) {
                    index = i
                    return true
                }
            })
            if(r.length) {
                r[0].readonly = this.check.hasCity
                r[0].icon = this.check.hasCity ? 'icon-right' : ''
                r[0].msg = this.check.hasCity ? this.$translate('Please Select') : this.$translate('Please Enter')
                this.$set(this.list, index, {...this.list[index], list: r})
            }
        },
        // spm
        setSpm(obj) {
            const spmObj = {
                d: obj.d || obj.item.spmD,
                g: {
                    pageId: obj.pageId || (obj?.list?.key && this.form[obj.list?.key]) || ''
                }
            };
            this.clear && clearTimeout(this.clear)
            this.clear = setTimeout(() => {
                this.$ctxApi.spm.initial(spmObj, this)
            }, 300)
        },
        // 初始化设置
        initialSet() {
            // this.country = this.$storage.get('country') || {}
            let postcodeShow = this.rules.postcodeShow;
            if(!postcodeShow) {
                this.rules.postcodeAhead = false
            }
            // list数据
            this.list = [{
                hasVisible: !this.rules.streetShow,
                spmD: '13',
                list: [{
                    title: `${this.$translate('Street')}`,
                    key: 'street',
                    max: 30,
                    class: 'address-form-street',
                    require: this.rules.streetRequired,
                    status: true,
                    placeholder: this.$translate('Street Name/Number'),
                    msg: this.rules.streetErrorMessage,
                    validator: (val) => {
                        return new RegExp(this.rules.streetRegex).test(val);
                    }
                }]
            }, {
                spmD: '6',
                list: [{
                    title: this.countryRe('Street address'),
                    key: 'addressLine1',
                    max: 50,
                    class: 'address-form-addressLine1',
                    require: true,
                    status: true,
                    placeholder: this.$translate('Street,Address,Company Name,C/O'),
                    msg: this.$translate('Street address should contain 5-50 letters, digits or spaces'),
                    hasPopoverSet: true,
                    hasPopoverStatus: false,
                    validator: (val) => {
                        return /^.{5,50}$/.test(val);
                    }
                }]
            }, {
                spmD: '7',
                list: [{
                    title: this.countryRe('Apt/Unit/Other(optional)'),
                    key: 'addressLine2',
                    class: 'address-form-addressLine2',
                    max: 100,
                    status: true,
                    require: this.country.countryCode=='DE' ? true : false,
                    msg: this.$translate('House number should contain 5-50 letters, digits or spaces'),
                    // placeholder: this.$translate('Apartment,Suite,Unit,Building,Foor,etc(Optional)'),
                }]
            }, {
                spmD: '10',
                hasVisible: !postcodeShow || !this.rules.postcodeAhead,
                list: [{
                    title: this.countryRe('Zip Code'),
                    key: 'postCode',
                    require: this.rules.postcodeRequired,
                    status: true,
                    class: 'address-form-postCode',
                    placeholder: this.rules.postcodePlaceholder || '',
                    msg: this.rules.zipCodeErrorMessage,
                    max: 20,
                    hasPopoverSet: true,
                    hasPopoverStatus: false,
                    validator: (val) => {
                        return new RegExp(this.rules.zipCodeRegex).test(val);
                    }
                }]
            }, {
                spmD: '9',
                list: [{
                    title: this.countryRe('City'),
                    key: 'city',
                    require: true,
                    status: true,
                    // readonly: this.check.hasCity,
                    hasFullHot: true,
                    max: 60,
                    // icon: this.check.hasCity ? 'icon-right' : '',
                    class: 'address-form-city',
                    // msg: this.check.hasCity ? this.$translate('Please Select') : this.$translate('Please Enter')
                    msg: this.$translate('Please Enter')
                }]
            }, {
                spmD: '8',
                list: [{
                    title: this.countryRe('State/Province'),
                    key: 'state',
                    require: true,
                    readonly: this.check.hasState,
                    hasFullHot: true,
                    max: 50,
                    status: true,
                    icon: this.check.hasState ? 'icon-right' : '',
                    class: 'address-form-state',
                    msg: this.check.hasState ? this.$translate('Please Select') : this.$translate('Please Enter')
                }]
            }, {
                spmD: '10',
                hasVisible: !postcodeShow || this.rules.postcodeAhead,
                list: [{
                    title: this.countryRe('Zip Code'),
                    key: 'postCode',
                    require: this.rules.postcodeRequired,
                    status: true,
                    class: 'address-form-postCode',
                    placeholder: this.rules.postcodePlaceholder || '',
                    msg: this.rules.zipCodeErrorMessage,
                    max: 20,
                    validator: (val) => {
                        return new RegExp(this.rules.zipCodeRegex).test(val);
                    }
                }]
            }, 
            // {
            //     spmD: '15',
            //     hasVisible: !(this.rules.taxTag && !this.query.bill && !['order/billAddress'].includes(this.query?.key)),
            //     list: [{
            //         title: this.$translate('Vat Number'),
            //         key: 'vatNumber',
            //         require: true,
            //         status: true,
            //         icon: 'icon-warn-grey',
            //         msg: this.rules.vatErrorMessage,
            //         hasPopover: false,
            //         hideIcon: true,
            //         class: 'address-form-vatNumber',
            //         tip: this.$translate('VAT NUMBER helps to deliver your package more smoothly'),
            //         validator: (val) => {
            //             return new RegExp(this.rules.vatRegex).test(val);
            //         }
            //     }]
            // }, 
            {
                spmD: '16',
                hasVisible: !this.rules.nationalIdShow,
                list: [{
                    title: this.$translate('National ID Number'),
                    key: 'nationalId',
                    icon: 'icon-warn-grey',
                    require: this.rules.nationalIdRequired,
                    status: true,
                    msg: this.rules.nationalIdErrorMessage,
                    class: 'address-form-nationId',
                    placeholder: this.$translate('For customs clearance purposes, please provide your ID'),
                    validator: (val) => {
                        return new RegExp(this.rules.nationalIdRegex).test(val);
                    }
                }]
            },{
                spmD: '17',
                hasVisible: !this.rules.taxRequired,
                list: [{
                    title: this.rules.taxTitle,
                    key: 'vatNumber',
                    require: this.rules.taxRequiredInput,
                    status: true,
                    class: 'address-form-postCode',
                    placeholder: this.rules.taxTipText || '',
                    msg: this.rules.taxErrorMessage,
                    max: 14,
                    validator: (val) => {
                        return new RegExp(this.rules.taxRegex).test(val);
                    }
                }]
            },]
        }
    },
}
