import { render, staticRenderFns } from "./AddressSubmit.vue?vue&type=template&id=41f56605&scoped=true&"
import script from "./AddressSubmit.vue?vue&type=script&lang=js&"
export * from "./AddressSubmit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41f56605",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnFooter: require('/home/php1/m-nuxt/components/YfnFooter.vue').default})
